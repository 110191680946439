<template>
  <div id="retro-bonus-table-page" :class="isThemeHeader === 'true' ? 'bg-dark' : 'bg-white'">
    <div class="inner-broadcast w-100">
      <SpinnerLoader :loading="isPartialUpdate" :float-in-corner="true" />

      <div class="p-3 d-flex gap-3 w-100 flex-wrap">
        <MultiSelect
          v-model="selects.years"
          style="width: 250px"
          class="multiselect-sm"
          :options="yearsList"
          :placeholder="$t('channelModal.year')"
          label="id"
          track-by="id"
          @input="loadTable"
        ></MultiSelect>
        <MultiSelect
          v-model="selects.holdings"
          style="width: 250px"
          class="multiselect-sm"
          :options="holdingsList"
          :placeholder="$t('holdings.holding')"
          label="name"
          track-by="id"
          @input="loadTable"
        ></MultiSelect>
        <MultiSelect
          v-model="selects.advertisers"
          style="width: 250px"
          class="multiselect-sm"
          :options="advertisersList"
          :placeholder="$t('table.advertisers')"
          label="name"
          track-by="id"
          :allow-empty="true"
          @input="loadTable"
        ></MultiSelect>
      </div>
    </div>
    <div v-if="RBDiscountsStatus == 'success'" class="w-100 px-3 pb-3">
      <div class="w-100 table table-responsive fixed-height">
        <transition name="fade" mode="out-in">
          <div v-if="RBDiscountsStatus === 'loading'" class="wrapper-broadcast__loader">
            <SpinnerLoader :loading="RBDiscountsStatus" />
          </div>
        </transition>
        <table
          v-if="RBDiscountsStatus == 'success'"
          id="oneDayTable"
          class="table table-sm table-hover w-auto mr-auto small table-hover table-mp-summary table-bordered ws-nowrap sticky-footer sticky-headers"
        >
          <thead>
            <tr>
              <!-- Месяц -->
              <th name="programTime" class="sticky-col" style="z-index: 11">
                <div class="resizable-container">{{ $t('reports.month') }}</div>
              </th>
              <!--   КП-Текущий -->
              <th name="programTime">
                <div style="width: 120px" class="resizable-container">{{ $t('RBDiscounts.CPCurrent') }}</div>
              </th>
              <!--   КП-Текущий накопленный -->
              <th name="programTime">
                <div style="width: 120px" class="resizable-container">{{ $t('RBDiscounts.CPCurrent') }} {{ $t('RBDiscounts.accumulated') }}</div>
              </th>
              <!--   Бюджет (до РБ) -->
              <th name="programTime">
                <div style="width: 120px" class="resizable-container">{{ $t('table.budget') }} ({{ $t('RBDiscounts.beforeRB') }})</div>
              </th>
              <!--   1, Бюджет (до РБ) макс по шкале -->
              <th name="spotName">
                <div class="resizable-container">
                  1, {{ $t('table.budget') }} ({{ $t('RBDiscounts.beforeRB') }}) <br />
                  {{ $t('RBDiscounts.maxOnTheScale') }}
                </div>
              </th>
              <!--   Скидка РБ расчетная -->
              <th name="programTime">
                <div class="resizable-container">{{ $t('RBDiscounts.RBDiscountEstimated') }}</div>
              </th>
              <!--   2, Бюджет (до РБ) накопленный -->
              <th name="programTime">
                <div class="resizable-container" style="width: 150px">
                  2, {{ $t('table.budget') }} ({{ $t('RBDiscounts.beforeRB') }}) <br />
                  {{ $t('RBDiscounts.accumulated') }}
                </div>
              </th>
              <!--   3, Бюджет (до РБ) накопленный PM-1 -->

              <th name="spotName">
                <div class="resizable-container">
                  <div class="resizable-container" style="width: 150px">
                    3, {{ $t('table.budget') }} ({{ $t('RBDiscounts.beforeRB') }}) <br />
                    {{ $t('RBDiscounts.accumulated') }} {{ $t('RBDiscounts.Adv') }}-1
                  </div>
                </div>
              </th>

              <!--   4, Бюджет (до РБ) накопленный c учетом предыдущих периодов -->

              <th name="programTime">
                <div class="resizable-container" style="width: 150px">
                  4, {{ $t('table.budget') }} ({{ $t('RBDiscounts.beforeRB') }}) <br />
                  {{ $t('RBDiscounts.accumulatedWithPrevPeriods') }}
                </div>
              </th>

              <!--   5, Абсолютная накопленная скидка -->

              <th name="programName" style="width: 150px">
                <div class="resizable-container">5, {{ $t('RBDiscounts.absoluteDiscount') }}</div>
              </th>

              <!--  6, Абсолютная накопленная скидка PM-1 -->

              <th name="spotName">
                <div class="resizable-container" style="width: 150px">6, {{ $t('RBDiscounts.absoluteDiscount') }} {{ $t('RBDiscounts.Adv') }}-1</div>
              </th>

              <!--  7, Абсолютная накопленная скидка с учетом предыдущих периодов -->

              <th name="programTime">
                <div class="resizable-container" style="width: 230px">7, {{ $t('RBDiscounts.absoluteDiscountWithPrevPeriods') }}</div>
              </th>

              <!--  8, Расчетная месячная скидка -->

              <th name="programName">
                <div class="resizable-container" style="width: 120px">8, {{ $t('RBDiscounts.estimatedMonthDiscount') }}</div>
              </th>

              <!-- 9.  Предварительная месячная скидка -->
              <th name="programName">
                <div class="resizable-container" style="width: 150px">9, {{ $t('RBDiscounts.preliminaryMonthDiscount') }}</div>
              </th>
              <!-- 10. Остаток месячной скидки в % -->
              <th name="programName">
                <div class="resizable-container" style="width: 150px">10, {{ $t('RBDiscounts.discountRemainderPercentage') }}</div>
              </th>
              <!-- 11. Остаток невыданной скидки по месяцу в абсолютном выражении -->
              <th name="programName">
                <div class="resizable-container" style="width: 150px">11, {{ $t('RBDiscounts.discountRemainderAbsolute') }}</div>
              </th>

              <!-- 12. Предварительный  бюджет -->
              <th name="programName">
                <div class="resizable-container" style="width: 150px">12, {{ $t('RBDiscounts.preliminaryBudget') }}</div>
              </th>

              <!-- 13. Абсолютная месячная скидка с учетом остатка -->

              <th name="programName">
                <div class="resizable-container" style="width: 150px">13, {{ $t('RBDiscounts.absoluteMonthlyDiscountIncludingRemainder') }}</div>
              </th>

              <!-- 14. Финальная месячная скидка -->

              <th name="programName">
                <div class="resizable-container" style="width: 150px">14, {{ $t('RBDiscounts.finalMonthDiscount') }}</div>
              </th>

              <!-- Утвержденная (откорректированная) финальная месячная скидка -->
              <th name="programName">
                <div class="resizable-container" style="width: 200px">
                  {{ $t('RBDiscounts.approvedFinalMonthlyDiscount') }} <br />
                  ({{ $t('RBDiscounts.Discount') }}(-)/{{ $t('RBDiscounts.Surcharge') }}(+))
                </div>
              </th>

              <!-- 15. Финальный бюджет -->
              <th name="programName">
                <div class="resizable-container" style="width: 150px">15, {{ $t('RBDiscounts.finalBudget') }}</div>
              </th>

              <!-- Утвержденный (откорректированный) финальный бюджет -->
              <th name="programName">
                <div class="resizable-container" style="width: 150px">{{ $t('RBDiscounts.approvedFinalBudget') }}</div>
              </th>
              <!-- Разница (недоплата) -->
              <th name="programName">
                <div class="resizable-container" style="width: 150px">{{ $t('reports.difference') }} ({{ $t('RBDiscounts.underpayment') }})</div>
              </th>
              <!-- 16. Скорректированный финальный бюджет -->
              <th name="programName">
                <div class="resizable-container" style="width: 150px">16, {{ $t('RBDiscounts.adjustedFinalBudget') }}</div>
              </th>
              <!-- 17. Сумма всех МП РК =1 без РБ скидки -->
              <th name="programName">
                <div class="resizable-container" style="width: 150px">17, {{ $t('RBDiscounts.sumOfAllAdv1WithoutDiscount') }}</div>
              </th>
              <!-- просто поле для проверки, должно = R (утвержденной откорректированной скидке) -->
              <th name="spotName">
                <div class="resizable-container" style="width: 150px; height: 20px">
                  {{ $t('table.totalDiscount') }}
                </div>
              </th>
              <!-- проверка ... -->
              <!-- <th name="spotName">
                <div class="resizable-container" style="width: 150px; height: 20px"></div>
              </th> -->
            </tr>
          </thead>

          <tbody class="overflow-hidden text-right">
            <tr v-for="(row, index) in RBDiscounts?.data?.retro_bonus" :key="index">
              <td class="sticky-col text-left">{{ row.month }}</td>

              <td>{{ row.calendar_plan_budget | toFixedAndSpaceNotAfterDot }}</td>

              <td>{{ row.accumulated_calendar_plan_budget | toFixedAndSpaceNotAfterDot }}</td>

              <td>{{ row.budget | toFixedAndSpaceNotAfterDot }}</td>

              <td>{{ row.retro_bonus_scale_max_budget | toFixedAndSpaceNotAfterDot }}</td>

              <td>{{ row.retro_bonus_scale_discount | toFixedAndSpaceNotAfterDot }}%</td>

              <td>{{ row.budget_accumulated | toFixedAndSpaceNotAfterDot }}</td>

              <td>{{ row.budget_accumulated_prev_month | toFixedAndSpaceNotAfterDot }}</td>

              <td>{{ row.budget_accumulated_prev_periods | toFixedAndSpaceNotAfterDot }}</td>

              <td>{{ row.absolute_accumulated_discount_budget | toFixedAndSpaceNotAfterDot }}</td>

              <td>{{ row.absolute_accumulated_discount_budget_prev_month | toFixedAndSpaceNotAfterDot }}</td>

              <td>{{ row.absolute_accumulated_discount_budget_prev_periods | toFixedAndSpaceNotAfterDot }}</td>

              <td>{{ row.calculated_discount | toFixedAndSpaceNotAfterDot(9) }}%</td>

              <td>{{ row.calculated_pre_discount | toFixedAndSpaceNotAfterDot(9) }}%</td>

              <td>{{ row.calculated_discount_reminder | toFixedAndSpaceNotAfterDot(9) }}%</td>

              <td>{{ row.calculated_budget_reminder | toFixedAndSpaceNotAfterDot }}</td>

              <td>{{ row.calculated_pre_budget | toFixedAndSpaceNotAfterDot }}</td>

              <td>{{ row.calculated_absolute_discount_budget | toFixedAndSpaceNotAfterDot }}</td>

              <td :class="{ 'bg-position-warning text-decoration-none': toFixed(approved[index]) != toFixed(row.final_discount) }">
                <div class="d-flex align-items-center h-100 d-inline-block">
                  <div class="text-right mr-2 w-100" :title="row.final_discount">{{ row.final_discount | toFixedAndSpaceNotAfterDot(3) }}%</div>
                  <b-icon
                    v-if="!row.is_month_closed"
                    icon="caret-right"
                    class="ml-auto cursor-pointer"
                    font-scale="1.5"
                    :title="$t('RBDiscounts.copyToRight')"
                    @click="copyDiscount(index)"
                  />
                </div>
              </td>

              <td class="pr-2" :class="{ 'bg-position-warning text-decoration-none': toFixed(approved[index]) != toFixed(row.final_discount) }">
                <b-input
                  v-model="approved[index]"
                  :class="{ 'text-danger font-weight-bold': approved[index] < -90 }"
                  :disabled="row.is_month_closed"
                  size="sm"
                  type="number"
                  step="any"
                  @blur="(e) => approveInput(index)"
                />
              </td>

              <td>{{ row.final_budget | toFixedAndSpaceNotAfterDot(2) }}</td>

              <td>{{ row.approved_final_budget | toFixedAndSpaceNotAfterDot }}</td>

              <td>{{ row.difference | toFixedAndSpaceNotAfterDot }}</td>

              <!-- 16 -->
              <td :class="{ 'bg-position-warning text-decoration-none': toFixed(row.corrected_final_budget) != toFixed(row.plan_fact_budget) }">
                {{ row.corrected_final_budget | toFixedAndSpaceNotAfterDot }}
              </td>
              <!-- 17 -->
              <td :class="{ 'bg-position-warning text-decoration-none': toFixed(row.corrected_final_budget) != toFixed(row.plan_fact_budget) }">
                {{ row.plan_fact_budget | toFixedAndSpaceNotAfterDot }}
              </td>
              <td :class="{ 'text-danger font-weight-bold': row.real_discount > 90 }">{{ row.real_discount | toFixedAndSpaceNotAfterDot(3) }}%</td>
              <!-- <td class="text-center">{{ toFixed(row.real_discount) == toFixed(row.approved_final_budget) ? 'TRUE' : 'FALSE' }}</td> -->
            </tr>

            <tr class="no-border">
              <td class="sticky-col text-left">{{ $t('booking.total') }}</td>

              <td>{{ sums.calendar_plan_budget | toFixedAndSpaceNotAfterDot }}</td>

              <td></td>

              <td>{{ sums.budget | toFixedAndSpaceNotAfterDot }}</td>

              <td></td>

              <td></td>

              <td></td>

              <td></td>

              <td></td>

              <td></td>

              <td></td>

              <td>{{ sums.absolute_accumulated_discount_budget_prev_periods | toFixedAndSpaceNotAfterDot }}</td>

              <td></td>

              <td></td>

              <td></td>

              <td></td>

              <td></td>

              <td
                :class="{ 'text-danger font-weight-bold': sums.calculated_absolute_discount_budget != sums.absolute_accumulated_discount_budget_prev_periods }"
              >
                {{ sums.calculated_absolute_discount_budget | toFixedAndSpaceNotAfterDot }}
              </td>

              <td>
                <b-button size="sm" :title="$t('RBDiscounts.copyAllTitle')" @click="copyAllDiscounts()">
                  {{ $t('RBDiscounts.copyAll') }}
                  <b-icon icon="caret-right" />
                </b-button>
              </td>

              <td></td>

              <td :class="{ 'text-danger font-weight-bold': sums.final_budget != sums.budget - sums.absolute_accumulated_discount_budget_prev_periods }">
                {{ sums.final_budget | toFixedAndSpaceNotAfterDot }}
              </td>

              <td
                :class="{ 'text-danger font-weight-bold': sums.approved_final_budget != sums.budget - sums.absolute_accumulated_discount_budget_prev_periods }"
              >
                {{ sums.approved_final_budget | toFixedAndSpaceNotAfterDot }}
              </td>

              <td></td>
              <td
                :class="{ 'text-danger font-weight-bold': sums.corrected_final_budget != sums.budget - sums.absolute_accumulated_discount_budget_prev_periods }"
              >
                {{ isFinite(sums.corrected_final_budget) ? sums.corrected_final_budget : '' | toFixedAndSpaceNotAfterDot }}
              </td>
              <td></td>
              <td></td>
              <!-- <td></td> -->
            </tr>
          </tbody>
        </table>
      </div>
      <div class="clearfix">
        <b-button class="float-right w-150px" @click="approveDiscounts()">{{ $t('RBDiscounts.approve') }}</b-button>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import MultiSelect from '@/components/MultiSelect';
import SpinnerLoader from '../components/SpinnerLoader';
import errorsHandler from '@/utils/errorsHandler';
import toFixedAndSpace from '@/filters/toFixedAndSpace';
import toFixedAndSpaceNotAfterDot from '@/filters/toFixedAndSpaceNotAfterDot';
import CurrencyInput from '@/components/CurrencyInput.vue';
export default {
  name: 'RetroBonusDiscounts',
  components: {
    SpinnerLoader,
    MultiSelect,
    CurrencyInput,
  },
  filters: {
    toFixedAndSpace,
    toFixedAndSpaceNotAfterDot,
    toFixed(number, toFixedValue) {
      return (+number).toFixed(toFixedValue);
    },
  },
  props: {
    advertiser_id: { type: [String, Number], default: undefined },
    holding_id: { type: [String, Number], default: undefined },
    year: { type: [String, Number], default: new Date().getFullYear() },
  },
  data() {
    return {
      hiddenPanel: false,
      isPartialUpdate: '',
      selects: {
        holdings: null,
        advertisers: null,
        years: { id: +new Date().getFullYear() },
      },
      approved: [],
      sums: {},
    };
  },
  computed: {
    ...mapGetters({
      permissions: 'getPermissions',
      isLocale: 'isLocale',
      isThemeHeader: 'isTheme',
      advertisersList: 'getAdvertisersList',
      holdingsList: 'getHoldingsList',
      yearsList: 'getYear',
      RBDiscounts: 'getRBDiscounts',
      RBDiscountsStatus: 'getRBDiscountsStatus',
    }),
    isSelected() {
      return this.selects.holdings != null && this.selects.years != null && this.selects.advertisers != null;
    },
  },
  created() {
    document.title = this.$i18n.t('RBDiscounts.retroBonusDiscounts') + ' – OpenMediaLogic';
  },
  mounted() {
    this.loadSelectsData().then(() => {
      this.selects.holdings = this.holdingsList.find((x) => x.id == this.holding_id);
      this.selects.years = this.yearsList.find((x) => x.id == this.year);
      this.selects.advertisers = this.advertisersList.find((x) => x.id == this.advertiser_id);
      this.loadTable();
    });
  },
  destroyed() {
    this.$store.commit('clearRBDiscounts');
  },
  methods: {
    toFixed(number, toFixedValue = 10) {
      return (+number).toFixed(toFixedValue);
    },
    updateQuery() {
      setTimeout(() => {
        if (
          this.$route.query.year != this.selects.years?.id ||
          this.$route.query.advertiser_id != this.selects.advertisers?.id ||
          this.$route.query.holding_id != this.selects.holdings?.id
        ) {
          this.$router.replace({
            query: {
              ...this.$route.query,
              advertiser_id: this.selects.advertisers?.id,
              year: this.selects.years?.id,
              holding_id: this.selects.holdings?.id,
            },
          });
        }
      }, 50);
    },
    async copyDiscount(index) {
      if (this.RBDiscounts.data.retro_bonus[index].is_month_closed == true) return;
      Vue.set(this.approved, index, this.RBDiscounts.data.retro_bonus[index].final_discount);
    },
    async loadSelectsData() {
      await Promise.all([
        this.$store.dispatch('GET_PERMISSIONS'),
        !this.advertisersList || this.advertisersList.length < 1 ? this.$store.dispatch('GET_ADVERTISERS', { per_page: 2000 }) : undefined,
        !this.holdings || this.holdings.length < 1 ? this.$store.dispatch('GET_HOLDINGS') : undefined,
        this.yearsList.length < 1 ? this.$store.dispatch('GET_YEAR') : undefined,
      ]);
    },
    async loadTable() {
      if (this.isSelected == false) {
        return;
      }
      this.updateQuery();
      await Promise.all([
        this.$store.dispatch('GET_RETRO_BONUS_DISCOUNTS', {
          year_id: this.selects.years.id,
          holding_id: this.selects.holdings.id,
          advertiser_id: this.selects.advertisers ? this.selects.advertisers.id : undefined,
        }),
      ]);
      if (this.RBDiscounts) {
        this.approved = this.RBDiscounts.data?.retro_bonus.map((row) => row.discount_approved);
        this.getSums(Object.keys(this.RBDiscounts.data?.retro_bonus[0]), this.RBDiscounts.data?.retro_bonus);
      }
    },

    getSums(sumsLabels, data) {
      for (const row of data) {
        for (const sum of sumsLabels) {
          this.sums[sum] = 0;
        }
      }
      for (const row of data) {
        for (const sum of sumsLabels) {
          this.sums[sum] = this.sums[sum] + row[sum];
        }
      }
    },

    async approveDiscounts() {
      let formData = {
        year_id: this.selects.years.id,
        holding_id: this.selects.holdings.id,
        advertiser_id: this.selects.advertisers.id,
        discounts: this.approved.map((x, i) => ({ month_id: i + 1, value: parseFloat(x) })),
      };
      await this.$store.dispatch('POST_RETRO_BONUS_DISCOUNTS_APPROVE', {
        formData,
        handler: () => {
          this.$notify({
            type: 'success',
            title: this.$i18n.t('RBDiscounts.discountsApproved'),
            text: name,
          });
          this.loadTable();
        },
        handlerError: (errors) => {
          errorsHandler(errors, this.$notify);
        },
      });
      this.isModalBusy = false;
    },

    approveInput(index) {
      if (this.approved[index] > 0 || this.approved[index] <= -100) {
        Vue.set(this.approved, index, 0);
      }
      this.getSums(Object.keys(this.RBDiscounts.data?.retro_bonus[0]), this.RBDiscounts.data?.retro_bonus);
    },

    copyAllDiscounts() {
      for (let i = 0; i < 12; i++) {
        this.copyDiscount(i);
      }
    },
  },
};
</script>

<style lang="sass">
@use "~/src/assets/sass/broadcast.sass"
@use "~/src/assets/sass/oneDayTable.sass"
</style>
<style lang="sass" src="../assets/sass/mediaPlanPlacementTypes.sass"></style>

<style lang="scss" scoped>
@use '~/src/assets/sass/oneDay.sass';
</style>
<style scoped>
th {
  white-space: nowrap;
}
th > div {
  word-wrap: break-word;
  white-space: normal !important;
  width: 100%;
  height: fit-content;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}
td {
  height: 1px;
  vertical-align: middle;
  padding-right: 6px !important;
}
.fixed-height {
  height: calc(100vh - 180px) !important;
}

tr.no-border > td {
  border: 0;
  box-shadow: none !important;
}
</style>
