/**
 * Returns spaced number with n digits after comma.
 *
 * @param {string | number} inputNumber
 * @param {number} toFixedValue The number of digits after comma. Default: 2.
 */
export default function toFixedAndSpaceNotAfterDot(inputNumber, toFixedValue = 2) {
  if (inputNumber !== null && inputNumber !== undefined && inputNumber !== '') {
    let string = String((+inputNumber).toFixed(toFixedValue));
    let values = string.split('.');
    let withSpaces = values[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return withSpaces + '.' + values[1];
  } else {
    return '';
  }
}
